<script context="module" lang="ts">
	export type RealTimePriceEvent =
		| {
				changeDirection: 'down' | 'flat' | 'up' | null;
				price: number;
				status: 'ok';
		  }
		| {
				message: string;
				status: 'error';
		  };
</script>

<script lang="ts">
	import type { TDTypes } from '@tickrr/twelvedata';

	import { page } from '$app/stores';
	import { createQuery } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import { onDestroy, onMount } from 'svelte';

	import { getStores } from '../../../../stores';

	const { rtpWebsocketClientStore } = getStores(['rtpWebsocketClientStore']);

	export let symbol: string;
	export let initialPrice: null | number = null;
	export let onPriceEvent: (e: RealTimePriceEvent) => void = () => {};

	let price: null | number = null;
	let error: null | string = null;
	let changeDirection: 'down' | 'flat' | 'up' | null = null;

	function handlePriceUpdate(
		message: TDTypes.Responses['WebSocketEndpoints']['RealTimePrice'] | null
	) {
		if (!message) return;

		if (message.event === 'price' && message.symbol === symbol) {
			const newPrice = message.price;
			changeDirection =
				(price ?? 0) > newPrice ? 'down' : (price ?? 0) < newPrice ? 'up' : 'flat';
			price = newPrice;
			onPriceEvent({ changeDirection, price, status: 'ok' });
		}
	}

	const initialPriceQuery = createQuery({
		enabled: initialPrice === null,
		onSuccess(data) {
			onPriceEvent({
				changeDirection: null,
				price: data.price,
				status: 'ok'
			});
		},
		queryFn: async () => {
			return await trpc($page).ticker.fetchRealTimePrice.query({ symbol });
		},
		queryKey: ['initialPrice', symbol]
	});

	onMount(async () => {
		try {
			await $rtpWebsocketClientStore.subscribeToSymbol(symbol);
		} catch (e) {
			logger.error({ e }, 'An error occurred while unsubscribing from the symbol.');
			onPriceEvent({
				message: 'An error occurred while subscribing to the symbol.',
				status: 'error'
			});
		}
	});

	onDestroy(async () => {
		try {
			await $rtpWebsocketClientStore.unsubscribeFromSymbol(symbol);
		} catch (e) {
			logger.error({ e }, 'An error occurred while unsubscribing from the symbol.');
			onPriceEvent({
				message: 'An error occurred while unsubscribing from the symbol.',
				status: 'error'
			});
		}
	});

	$: initialPrice = $initialPriceQuery.data?.price ?? null;

	$: latestMessage = $rtpWebsocketClientStore.latestMessage;
	$: handlePriceUpdate($latestMessage);
</script>

<slot
	{changeDirection}
	{error}
	{initialPrice}
	latestMessage={$latestMessage}
	realTimePrice={price}
/>
