/**
 * Adopted from https://svelte.dev/examples/immutable-data
 * @param element the element to flash.
 * @returns void
 */
export function flashOnUpdate(
	element: HTMLElement,
	{
		backgroundColor = 'transparent',
		color = 'rgb(var(--color-primary-500))',
		duration = 1000
	}: {
		backgroundColor?: string;
		color?: string;
		duration?: number;
	} = {}
) {
	requestAnimationFrame(() => {
		element.style.transition = 'none';
		element.style.color = color;
		element.style.backgroundColor = backgroundColor;

		setTimeout(() => {
			element.style.transition = `color ${duration}ms, background ${duration}ms`;
			element.style.color = '';
			element.style.backgroundColor = '';
		});
	});
}
